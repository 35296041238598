section.news-112 {
    position: relative;
    .news-item {
        .make-xs-column(12);
        .make-sm-column(4);
        @media @small {
            &:last-child {
                display: none;
            }
        }
        @media @phone, @xsmall, @small {
            margin-bottom: 30px;
        }
        .newslink {
            height: auto;
            background: none;
            &:hover {
            }
            span {
                height: auto;
            }
        }
    }

}
