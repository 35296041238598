section.banners-122 {
    .col1 {
        .make-sm-column(4);
		@media @phone, @xsmall {
			margin-bottom: 30px;
		}

    }
    .col2 {
        .make-sm-column(4);
		@media @phone, @xsmall {
			margin-bottom: 30px;
		}
    }
    .col3 {
        .make-sm-column(4);
    }

    .banner {
		word-wrap:break-word;
    }
}
