.banner {
	display: block;
	position: relative;
	text-align: left;
	background: @red;
	.lh-background-image(linear-gradient(to bottom, @red 0%, @grdarkred 100%));
	padding: 20px 20px 40px 20px;
	.lh-transition(all 0.3s ease);
	figure {
		.lh-border-radius(50%);
		background-color: @white;
		img {
			.lh-transition(all 0.3s ease);
		}
		overflow: hidden;
		margin-bottom: 20px;
	}
	h3, p {
		color: @white !important;
	}
	&:hover {
		background: @mo_light;
		.lh-background-image(linear-gradient(to bottom, @mo_light 0%, @mo_dark 100%));
		img {
			transform: scale(1.1);
		}
		h3, p {
			color: @white !important;
		}
	}
	&:after {
		display: inline-block;
		text-align: center;
		position: absolute;
		left: 50%;
		bottom: 15px;
		width: 32px;
		height: 32px;
		margin-left: -16px;
		margin-top: 10px;
		.icon;
		line-height: 32px;
		content: '\f054';
		color: @white;
		.font-size(20) !important;
		font-weight: normal !important;
	}

	.banners-126 & {
		padding: 5px 5px 40px 5px;
		background: none;
		h3 {
			color: @color2 !important;
		}
		&:after {
			color: @textcolor;
		}
		figure {
			border-radius: 0;
		}
		p {
			display: none;
		}
		margin: 0 -15px;
		border: 1px solid @grey;
		&:hover {
			border-color: @color2;
			&:after {
				color: @color2;
			}
		}
	}
}
/*
.banner {
    display: block;
    text-align: left;
    background-color: @color1;
    .clearfix;
	position: relative;
	.lh-transition(all 0.3s ease);
	.lh-box-shadow(0 13px 11px -8px fade(@darkgrey, 50%));
	@media @small, @medium, @large {
		height: 220px;
	}

	@media @phone, @xsmall {
		height: 160px;
	}
	word-wrap:break-word;


	overflow: hidden;
	position: relative;
	h3 {
		position: absolute;
		top: 60px;
		left: 15px;
		right: 15px;
		text-align: center;
		color: @white !important;
		font-weight:600;
		font-size: 30px !important;
		text-shadow: 2px 3px 3px @darkgrey;
		@media @phone, @xsmall {
			top: 50px;
			font-size: 24px !important;
		}
	}
    figure {
		@media @small, @medium, @large {
			height: 220px;
		}

		@media @phone, @xsmall {
			height: 160px;
		}
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		.lh-transition(all 0.2s ease);
    }


}
*/