section.banners-126 {
  .banner-container {
  }
  h3 {
    text-align: center;
    @media @phone, @xsmall {
      .font-size(18) !important;
    }
  }
  .col1 {
    .make-xs-column(6);
    .make-sm-column(4);
    .make-md-column(2);
    @media @phone, @xsmall, @small {
      margin-bottom: -1px;
    }
  }
  .col2 {
    .make-xs-column(6);
    .make-sm-column(4);
    .make-md-column(2);
    @media @phone, @xsmall, @small {
      margin-bottom: -1px;
    }
  }

  .col3 {
    .make-xs-column(6);
    .make-sm-column(4);
    .make-md-column(2);
    @media @phone, @xsmall, @small {
      margin-bottom: -1px;
    }
    @media @phone, @xsmall {
      clear: both;
    }
  }
  .col4 {
    .make-xs-column(6);
    .make-sm-column(4);
    .make-md-column(2);
    @media @phone, @xsmall {
      margin-bottom: -1px;
    }
    @media @small {
      clear: both;
    }
  }
  .col5 {
    .make-xs-column(6);
    .make-sm-column(4);
    .make-md-column(2);
    @media @phone, @xsmall {
      clear: both;
    }
  }
  .col6 {
    .make-xs-column(6);
    .make-sm-column(4);
    .make-md-column(2);
  }

}

