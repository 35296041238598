section.carrousel-100 {

    &.wide {
        .col1 {
            padding: 0;
        }
    }

    ul {
        .no-bullets;

        li {
            position: relative;

            figure {
				img {
					margin: 0 auto;
				}
            }

            h5 {
                position: absolute;
                bottom: 10%;
                left: 0;
                width: 100%;
                text-align: center;
            }
        }
    }
	.bx-pager {
		bottom: auto;
		padding-top: 40px;
		.bx-pager-item a {
			background-color: @color1;
			&.active {
				background-color: @darkgrey;
			}
		}
	}
	.bx-controls-direction a.bx-next, 
	.bx-controls-direction a.bx-prev {
		background: none;
	}
	
	.bx-controls-direction a {
		text-indent: 0;
		color: @color1;
		&:before {
			.icon;
			.font-size(32);
		}
		&.bx-prev:before {
			content: '\f053';
		}
		&.bx-next:before {
			content: '\f054';
		}
		&:hover {
			text-decoration: none;
			color: @darkgrey;
		}
	}
}
