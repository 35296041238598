section.banners-128 {
    //margin-top: 30px;
    //margin-bottom: 30px;
    padding-bottom: 0;
    position: relative;

    .col1 {
        padding: 0 15px;
    }
}
.banner-breed {
    display: block;
    position: relative;
    text-align: left;
    background: @red;
    .lh-background-image(linear-gradient(to bottom, @red 0%, @darkred 100%));
    padding: 20px 15px 0 15px;
    .lh-transition(all 0.3s ease);
    .clearfix;
    figure {
        .make-sm-column(3);
        .lh-border-radius(50%);
        padding: 0;
        background-color: @white;
        img {
            .lh-transition(all 0.3s ease);
        }
        overflow: hidden;
        margin-bottom: 20px;
    }
    h2, h3, p {
        color: @white !important;
    }
    span.left {
        .make-sm-column(3);
    }
    span.right {
        .make-sm-column(6);
        padding-bottom: 40px;
    }
    &:hover {
        //background: @green;
        //.lh-background-image(linear-gradient(to bottom, @green 0%, @darkgreen 100%));
        background: @mo_dark;
        .lh-background-image(linear-gradient(to bottom, @mo_light 0%, @mo_dark 100%));
        img {
            transform: scale(1.1);
        }
        h3, h2, p {
            color: @white !important;
        }
    }


    &:after {
        text-align: center;
        position: inherit;
        left: 50%;
        bottom: 15px;
        width: 32px;
        height: 32px;
        margin-left: -16px;
        margin-top: 10px;
        .icon;
        line-height: 32px;
        content: '\f054';
        color: @white;
        .font-size(20) !important;
        font-weight: normal !important;
    }
}