// --------------------------------------------------
// Global settings
// --------------------------------------------------

// Colors
@black: #000;
@white: #FFF;

@green: #d0d241;
@darkgreen: #87892a;
@grdarkgreen: #a6a834;
@lightgreen: #d9db67;

@red: #e61938;
@darkred: #961024;
@grdarkred: #8a0e20;
@lightred: #eb4760;

@grey: #dce0df;
@mediumgrey: #8f9291;
@darkgrey: #555657;
@lightgrey: #e3e6e5;

@mo_light: #555657;
@mo_dark: #121212;


@banner-text: @black;

@color1: @green;
@color2: @red;
@contrast1: @grey;
@contrast2: @darkgreen;
@textcolor: @darkgrey;
@titlecolor: @darkgrey;


// mix with white: tint()
// mix with black: shade()


// Typography
@contentfont: 'Source Sans Pro', sans-serif;
@titlefont: @contentfont;
@subtitlefont: @titlefont;

@titleweight: 900;
@subtitleweight: 600;

// Templates
@leegheight: 100px; //400px;

// Breakpoints
/* Extra small vertical devices (phones, less than 480px) */
@phone:                     ~"only screen and (max-width: @{screen-xs-min})";

/* Extra small devices (horizontal phones, less than 768px) */
@xsmall:                    ~"only screen and (min-width: @{screen-xs-min}) and (max-width: @{screen-xs-max})";

/* Small devices (tablets, 768px and up) */
@small:                     ~"only screen and (min-width: @{screen-sm-min}) and (max-width: @{screen-sm-max})";

/* Medium devices (desktops, 992px and up) */
@medium:                    ~"only screen and (min-width: @{screen-md-min}) and (max-width: @{screen-md-max})";

/* Large devices (large desktops, 1200px and up) */
@large:                     ~"only screen and (min-width: @{screen-lg-min})";

/* Higher pixel density screens */
@retina:                    ~"only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)";

@mobile: @phone, @xsmall;
@nonmobile: @small, @medium, @large;

